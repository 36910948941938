import { useEffect, useState } from 'react';
import Button from '../../../../../../components/button';
import Input from '../../../../../../components/input';
import ItemCard from '../../../../../../components/itemCard';
import { Modal } from '../../../../../../components/modal';
import Textarea from '../../../../../../components/textarea';
import {
  ICurrencyProvider,
  ISingleCurrencyType,
} from '../../../../../../interface/settings';

interface ICardLimits {
  providerData?: ISingleCurrencyType;
  updateValues: (updates: Partial<ICurrencyProvider>) => void;
  isLoading: boolean;
}

const CardLimits = ({ providerData, updateValues, isLoading }: ICardLimits) => {
  const [open, setOpen] = useState(false);
  const [cardLimits, setCardLimits] = useState<ICurrencyProvider>(
    providerData?.providers!
  );
  const [modifiedFields, setModifiedFields] = useState<
    Partial<ICurrencyProvider>
  >({});

  const submit = () => {
    updateValues(modifiedFields);
    setOpen(false);
  };

  const handleFieldChange = (key: keyof ICurrencyProvider, value: any) => {
    const newValue = key === 'instruction' ? value : Number(value);
    setCardLimits((prev) => ({
      ...prev,
      [key]: newValue,
    }));
    setModifiedFields((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  };

  useEffect(() => {
    if (providerData) setCardLimits(providerData.providers || {});
  }, [providerData]);

  return (
    <div>
      <div onClick={() => setOpen(true)}>
        <ItemCard title="Card Limit" />
      </div>

      <Modal
        padding={true}
        isVisible={open}
        center
        close={setOpen}
        title="Card Limits"
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} disabled={isLoading} loading={isLoading}>
              Save Changes
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-4 text-sm">
          <div>
            <h5>Maximum single deposit</h5>
            <Input
              parentClassName="mt-2"
              defaultValue={cardLimits.maxDepositPerTime}
              onChange={(e) =>
                handleFieldChange('maxDepositPerTime', Number(e.target.value))
              }
            />
          </div>
          <div>
            <h5>Maximum single transaction</h5>
            <Input
              parentClassName="mt-2"
              defaultValue={cardLimits.maxSpendPerTime}
              onChange={(e) =>
                handleFieldChange('maxSpendPerTime', Number(e.target.value))
              }
            />
          </div>
          <div>
            <h5>Minimum funding on creation</h5>
            <Input
              parentClassName="mt-2"
              defaultValue={cardLimits.minFundingOnCreation}
              onChange={(e) =>
                handleFieldChange(
                  'minFundingOnCreation',
                  Number(e.target.value)
                )
              }
            />
          </div>
          <div>
            <h5>Minimum funding</h5>
            <Input
              parentClassName="mt-2"
              defaultValue={cardLimits.minFund}
              onChange={(e) =>
                handleFieldChange('minFund', Number(e.target.value))
              }
            />
          </div>
          <div>
            <h5>Maximum card balance</h5>
            <Input
              parentClassName="mt-2"
              defaultValue={cardLimits.maxCardBalance}
              onChange={(e) =>
                handleFieldChange('maxCardBalance', Number(e.target.value))
              }
            />
          </div>
          <div>
            <h5>Monthly Transaction limit</h5>
            <Input
              parentClassName="mt-2"
              defaultValue={cardLimits.monthlyTxnLimit}
              onChange={(e) =>
                handleFieldChange('monthlyTxnLimit', Number(e.target.value))
              }
            />
          </div>
          <div>
            <h5>Minimum card balance</h5>
            <Input
              parentClassName="mt-2"
              defaultValue={cardLimits.minCardBalance}
              onChange={(e) =>
                handleFieldChange('minCardBalance', Number(e.target.value))
              }
            />
          </div>
          <div>
            <h5 className="mb-2">Instruction</h5>
            <Textarea
              defaultValue={cardLimits.instruction}
              name="instruction"
              onChange={(e) => handleFieldChange('instruction', e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CardLimits;
