import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from '../../components/layout/main';
import User from './user';
import Dashboard from './dashboard';
import Transaction from './transaction';
import UserDetails from './user/details';

import Settings from './settings';
import { IUser } from '../../interface/user';
import { useGetCurrentUser } from '../../services/user';
import Business from './business';
import Notifications from './notifications';
import PendingKyc from './user/pendingKyc';
import InactiveUsers from './user/inactiveUsers';

const Main = () => {
  const [userData, setUserData] = useState<IUser>({} as IUser);
  const { data: currentUser } = useGetCurrentUser();

  useEffect(() => {
    if (currentUser?.data) {
      setUserData(currentUser.data);
    }
  }, [currentUser]);

  return (
    <Routes>
      <Route path="/" element={<MainLayout userData={userData} />}>
        <Route path="/dashboard" element={<Navigate replace to="/app" />} />
        <Route path="/transaction" element={<Transaction />} />
        <Route path="/user" element={<User />} />
        <Route path="/user/:id" element={<UserDetails />} />
        <Route path="/user/pending-kyc" element={<PendingKyc />} />
        <Route path="/user/inactive-users" element={<InactiveUsers />} />
        <Route path="/business" element={<Business />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route index element={<Dashboard />} />
      </Route>
    </Routes>
  );
};

export default Main;
