import { useContext, useEffect, useState } from 'react';
import Button from '../../../../../../components/button';
import Input from '../../../../../../components/input';
import ItemCard from '../../../../../../components/itemCard';
import { Modal } from '../../../../../../components/modal';
import GlobalContext from '../../../../../../context/globalContext';
import {
  ICurrencyProvider,
  ICurrencyType,
  ISingleCurrencyType,
} from '../../../../../../interface/settings';
import { useGetCards } from '../../../../../../services/cards';

interface ICardCreationFee {
  providerData?: ISingleCurrencyType;
  updateValues: (updates: Partial<ICurrencyProvider>) => void;
  isLoading: boolean;
  selectedProvider: string;
}

const CardCreationFee = ({
  providerData,
  updateValues,
  isLoading,
  selectedProvider,
}: ICardCreationFee) => {
  const [open, setOpen] = useState(false);
  const { providerDetails } = useContext(GlobalContext);
  const [currency, setCurrency] = useState<string>('');
  const [creationFee, setCreationFee] = useState<string>('');

  const submit = () => {
    updateValues({ creationFee: Number(creationFee) });
    setOpen(false);
  };

  const { data } = useGetCards({
    conversion: 'USDNGN',
    type: providerDetails.type,
    currency: currency,
    variant: selectedProvider.toLowerCase(),
  });

  useEffect(() => {
    if (providerData)
      setCurrency(providerData.providers.currency.toLowerCase());
  }, [providerData, setCurrency]);

  useEffect(() => {
    if (providerData) {
      setCreationFee(String(providerData.providers.creationFee));
    }
  }, [providerData, setCreationFee]);

  return (
    <div>
      <div onClick={() => setOpen(true)}>
        <ItemCard
          title="Card Creation Fee"
          rate={data?.data.buy}
          markup={providerData?.providers.creationFee}
        />
      </div>

      <Modal
        padding={true}
        isVisible={open}
        center
        close={setOpen}
        title="Card Creation Fee"
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} disabled={isLoading} loading={isLoading}>
              Save Changes
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-4 text-sm">
          <div>
            <div className="flex justify-between">
              <h5>Enter Amount</h5>
              <h6>C-Rate: ₦{data?.data.buy}</h6>
            </div>
            <Input
              parentClassName="mt-2"
              defaultValue={creationFee}
              onChange={(e) => setCreationFee(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CardCreationFee;
