import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useToast } from '../context/toast';
import { IKycResponse, IList, IResponse } from '../interface/response';
import {
  IBvnResponse,
  ICreditUser,
  IMessageUserPayload,
  IMessageUserResponse,
  InactiveUsers,
  IPendingKycResponse,
  IProviderRefund,
  IRepushPayload,
  ITransferStatus,
  IUpdateUser,
  IUser,
  IUserCount,
  IUserVerficationResponse,
  IUserVerify,
} from '../interface/user';
import Axios from './axios';

const getUserCount = async () => {
  const response: AxiosResponse<IResponse<IUserCount>> = await Axios.get(
    '/user/count'
  );
  return response.data;
};

const getCurrentUser = async () => {
  const response: AxiosResponse<IResponse<IUser>> = await Axios.get('/user/me');
  return response.data;
};

const getUserBvn = async (id?: string) => {
  const response: AxiosResponse<IResponse<IBvnResponse>> = await Axios.get(
    `/user/bvn/${id}`
  );
  return response.data;
};

const getUserNIN = async (id?: string) => {
  const response: AxiosResponse<IResponse<IBvnResponse>> = await Axios.get(
    `/user/nin/${id}`
  );
  return response.data;
};

const users = async (params: object) => {
  const response: AxiosResponse<IResponse<IList<IUser>>> = await Axios.get(
    '/user/all',
    {
      params,
    }
  );
  return response.data;
};

const inActiveUsers = async (params?: object) => {
  const response: AxiosResponse<IResponse<IList<InactiveUsers>>> =
    await Axios.get(`/user/admin/inactive-users`, { params });
  return response.data;
};

const user = async (id?: string) => {
  const response: AxiosResponse<IResponse<IUser>> = await Axios.get(
    `/user/${id}`
  );
  return response.data;
};

const updateSpecificUser = async ({
  id,
  payload,
}: {
  id: string;
  payload: IUpdateUser;
}) => {
  const response: AxiosResponse<IResponse<IUser>> = await Axios.patch(
    `/user/${id}`,
    payload
  );
  return response;
};

const updateUser = async (payload: object) => {
  const response: AxiosResponse<IResponse<IUser>> = await Axios.patch(
    `/user/find`,
    { ...payload }
  );
  return response;
};

const providerRefund = async (payload: IProviderRefund) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.post(
    `/transaction/provider/refund`,
    payload
  );
  return response;
};

const repush = async (payload: IRepushPayload) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.post(
    `/providus/repush`,
    payload
  );
  return response;
};

const safehavenTransferStatus = async (payload: ITransferStatus) => {
  const response: AxiosResponse<IResponse<IUser>> = await Axios.get(
    `/safehaven/transfer/status/${payload.session_id}?dynamic=${payload.dynamic}`
  );
  return response.data;
};

const messageUser = async (payload: IMessageUserPayload) => {
  const response: AxiosResponse<IResponse<IMessageUserResponse>> =
    await Axios.post(`/user/message/`, payload);
  return response;
};

const getUserVerificationData = async (params: object) => {
  const response: AxiosResponse<IResponse<IUserVerficationResponse[]>> =
    await Axios.get(`/user/admin/user-verification`, {
      params,
    });

  return response;
};

const creditUser = async (payload: ICreditUser) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.post(
    `/user/admin/wallet/credit `,
    payload
  );
  return response;
};

const debitUser = async (payload: ICreditUser) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.post(
    `/user/admin/wallet/debit `,
    payload
  );
  return response;
};

const verifyUserBvnOrNin = async (payload: IUserVerify) => {
  const response: AxiosResponse<IResponse> = await Axios.post(
    `/user/admin/user-verification-completion`,
    payload
  );
  return response;
};

const getPendingKyc = async (params: object) => {
  const response: AxiosResponse<IResponse<IKycResponse<IPendingKycResponse>>> =
    await Axios.get(`/kyc/pending-kyc-selfie`, { params });
  return response.data;
};

const approveKycSelfie = async (id: string) => {
  const response: AxiosResponse<IResponse> = await Axios.post(
    `/kyc/approve-kyc-selfie/${id}`
  );
  return response;
};

const declineKycSelfie = async (id: string) => {
  const response: AxiosResponse<IResponse> = await Axios.post(
    `/kyc/reject-kyc-selfie/${id}`
  );
  return response;
};

export const useUserCount = () => {
  const { addToast } = useToast();
  return useQuery('userCount', getUserCount, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useGetCurrentUser = () => {
  const { addToast } = useToast();
  return useQuery('currentUser', getCurrentUser, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useUsers = (params: any) => {
  const { addToast } = useToast();

  return useQuery(['users', params], () => users(params), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useInactiveUsers = (params: any) => {
  const { addToast } = useToast();

  return useQuery(['in-active-users', params], () => inActiveUsers(params), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching inactive',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useUser = (id?: string) => {
  const { addToast } = useToast();

  return useQuery(['user', id], () => user(id), {
    enabled: !!id,
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useSafehavenTransferStatus = (payload: ITransferStatus) => {
  const { addToast } = useToast();

  return useQuery(
    ['transfer status', payload.session_id],
    () => safehavenTransferStatus(payload),
    {
      enabled: false,
      onSuccess: (res) => {
        addToast({
          msg: res.message || 'Transfer status successful',
          type: 'success',
        });
      },
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || 'Error getting transfer status',
            type: 'error',
          });
        } else {
          addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
        }
      },
    }
  );
};

export const useGetUserBvn = ({
  id,
  enabled,
}: {
  id?: string;
  enabled: boolean;
}) => {
  const { addToast } = useToast();

  return useQuery(['userBvn', id], () => getUserBvn(id), {
    enabled,
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: `${err.response.data.message}` || 'Error fetching BVN',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useGetUserNIN = ({
  id,
  enabled,
}: {
  id?: string;
  enabled: boolean;
}) => {
  const { addToast } = useToast();

  return useQuery(['userNin', id], () => getUserNIN(id), {
    enabled,
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: `${err.response.data.message}` || 'Error fetching NIN',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useUpdateUser = () => {
  const { addToast } = useToast();

  return useMutation(updateUser, {
    onSuccess: async (response: AxiosResponse<IResponse<IUser>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Could not login',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useUpdateSpecificUser = () => {
  const { addToast } = useToast();

  return useMutation(updateSpecificUser, {
    onSuccess: async (response: AxiosResponse<IResponse<IUser>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Could not login',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useProviderRefund = () => {
  const { addToast } = useToast();

  return useMutation(providerRefund, {
    onSuccess: async (response: AxiosResponse<IResponse<any>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Could not send request',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useRepush = () => {
  const { addToast } = useToast();

  return useMutation(repush, {
    onSuccess: async (response: AxiosResponse<IResponse<any>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Could not send request',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useMessageUser = () => {
  const { addToast } = useToast();

  return useMutation(messageUser, {
    onSuccess: async (
      response: AxiosResponse<IResponse<IMessageUserResponse>>
    ) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Message not sent',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useGetUserVerificationData = (params: any) => {
  const { addToast } = useToast();

  return useQuery(
    ['verification data', params],
    () => getUserVerificationData(params),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || 'Error fetching user data',
            type: 'error',
          });
        } else {
          addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
        }
      },
      keepPreviousData: true,
    }
  );
};

export const useVerifyUserBvnOrNin = () => {
  const { addToast } = useToast();

  return useMutation(verifyUserBvnOrNin, {
    onSuccess: async (response: AxiosResponse<IResponse>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'User Verififation failed',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useCreditUser = () => {
  const { addToast } = useToast();

  return useMutation(creditUser, {
    onSuccess: async (response: AxiosResponse<IResponse<any>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Cannot credit user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useDebitUser = () => {
  const { addToast } = useToast();

  return useMutation(debitUser, {
    onSuccess: async (response: AxiosResponse<IResponse<any>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Cannot debit user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useGetPendingKyc = (params: any) => {
  const { addToast } = useToast();

  return useQuery(['pending kyc', params], () => getPendingKyc(params), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching pending kyc',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useApproveKycSelfie = () => {
  const { addToast } = useToast();

  return useMutation(approveKycSelfie, {
    onSuccess: async (response: AxiosResponse<IResponse<any>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Could not send request',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useDeclineKycSelfie = () => {
  const { addToast } = useToast();

  return useMutation(declineKycSelfie, {
    onSuccess: async (response: AxiosResponse<IResponse<any>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Could not send request',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};
