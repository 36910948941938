import { IBusiness } from './business';
import { IList } from './response';
import { ITxn, ITxnFilter } from './txn';

export interface IUser {
  _id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  address: any;
  role: string;
  password: string;
  suspend: boolean;
  deactivate: boolean;
  hasPin: boolean;
  country: string;
  reservedAccount: number;
  dialCode: string;
  wallet: number;
  trap: Trap;
  verification: Verification;
  account: Account[];
  createdAt: string;
  updatedAt: string;
  commission: Commission;
  __v: number;
  pin: string;
  canSendToAllBank: boolean;
  services: IServices;
  fundSweep: boolean;
}

export interface InactiveUsers {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  updatedAt: string;
  transactionCount: number;
  totalSettlement: number;
  inactiveReason: string;
}

export interface IServices {
  airtime: boolean;
  data: boolean;
  electricity: boolean;
  cheapData: boolean;
  cable: boolean;
  betting: boolean;
  giftcard: boolean;
  cardCreation: boolean;
  cardFunding: boolean;
  cardWithdrawal: boolean;
  figurToFigur: boolean;
  transfer: boolean;
  autoRecreatePro: boolean;
  skipTerminationDueToCharge: boolean;
  canSendToAllBank: boolean;
}

export interface IUserProps {
  user?: IUser;
}

export interface Account {
  accountReference: string;
  createdOn: Date;
  currencyCode: string;
  bankName: string;
  accountNumber: string;
  bvnVerified?: boolean;
  accountName: string;
}

export interface Verification {
  phone: boolean;
  bvn: boolean;
  nin: boolean;
  lock: boolean;
  lockReason: any;
  bvnSelfieVerified: boolean;
  bvnSelfieStatus: BvnSelfieStatusEnum;
  bvnSelfieScore: number;
  bvnSelfie: string;
}
export enum BvnSelfieStatusEnum {
  PENDING = 'PENDING',
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export interface IUserCount {
  allUser: number;
  verifiedUser: number;
  unverifiedUser: number;
  suspendedUser: number;
}

export interface IUserFilter {
  suspend?: boolean;
  'verification.bvn'?: boolean;
  search?: string;
  startDate?: string;
  endDate?: string;
}

export interface InactiveUserFilter {
  maxTransactions?: number;
  period?: string;
  startDate?: string;
  endDate?: string;
}

export enum PeriodEnum {
  WEEK = 'week',
  MONTH = 'month',
  CUSTOM = 'custom',
}

export interface IUserDetailsProps {
  user: IUser;
  txn: IList<ITxn>;
}

export interface IUserTransactionsProps {
  txn: IList<ITxn>;
  isLoading: boolean;
  txnCurrentPage: number;
  setTxnCurrentPage: (value: number) => void;
  filter: ITxnFilter;
  setFilter: (value: ITxnFilter) => void;
  size: number;
  setTxnSize: (value: number) => void;
  user?: IUser;
}

export interface IUserBusinessProps {
  business: IList<IBusiness>;
  isLoading: boolean;
  refetch: any;
  businessCurrentPage: number;
  setBusinessCurrentPage: (value: number) => void;
  size: number;
  setBusinessSize: (value: number) => void;
}

export interface IBvnResponse {
  dob: string;
  email: string;
  firstName: string;
  gender: string;
  identity: string;
  lastName: string;
  phone: string;
  image: string;
  selfieImage: string;
}

export enum TrapTypeEnum {
  SUSPEND = 'SUSPEND',
  NO_WITHDRAW = 'NO_WITHDRAW',
}

export interface IProviderRefund {
  amount: number;
  _id: string;
  business: boolean;
  bank: string;
  accountNumber: string;
  accountName: string;
  secret: string;
}

export interface IRepushPayload {
  settlement_id: string;
  session_id: string;
}

export interface ITransferStatus {
  session_id: string;
  dynamic: boolean;
}

export interface IMessageUserPayload {
  user: string;
  title: string;
  message: string;
}

export interface IMessageUserResponse {
  message: string;
  success: boolean;
}

export interface IUserVerficationResponse {
  identity: string;
  purpose: string;
  updatedAt: string;
  user: string;
  verified: boolean;
  _id: string;
  data: { entity: IUserEntity };
}

export interface IUserEntity {
  last_name: string;
  level_of_account: string;
  lga_of_origin: string;
  lga_of_residence: string;
  marital_status: string;
  middle_name: string;
  name_on_card: string;
  nationality: string;
  nin: string;
  phone_number1: string;
  phone_number2: string;
  registration_date: string;
  residential_address: string;
  state_of_origin: string;
  state_of_residence: string;
  status: number;
  title: string;
  watch_listed: string;
  image: string;
  bvn: string;
  customer: string;
  date_of_birth: string;
  email: string;
  enrollment_bank: string;
  enrollment_branch: string;
  first_name: string;
  gender: string;
}

export interface IUserVerify {
  identity: string;
  secret: string;
}

export interface ICreditUser {
  userId: string;
  amount: number;
  secret: string;
}

export interface IPendingKycResponse {
  _id: string;
  user: IUser;
  verified: boolean;
  purpose: string;
  identity: string;
  bvnSelfieStatus: string;
  selfieFromProviderBase64: string;
  selfieFromProviderUrl: string;
  userUploadedSelfie: string;
}

export interface Trap {
  amount: number;
  status: boolean;
  type: string;
}

export interface Commission {
  redeemed: boolean;
}

export interface ReconciliationResponse {
  totalCredits: TotalIts;
  totalDebits: TotalIts;
  dateRange: DateRange;
}

export interface DateRange {
  from: Date;
  to: Date;
}

export interface TotalIts {
  amount: number;
  currency: string;
  count: number;
}

export interface IUpdateUser {
  secret: string;
  canSendToAllBank: boolean;
}
