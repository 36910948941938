import { useEffect, useState } from 'react';
import Button from '../../../../../../components/button';
import Input from '../../../../../../components/input';
import ItemCard from '../../../../../../components/itemCard';
import { Modal } from '../../../../../../components/modal';
import {
  ICharge,
  ICurrencyProvider,
  ISingleCurrencyType,
} from '../../../../../../interface/settings';

interface ICharges {
  providerData?: ISingleCurrencyType;
  updateValues: (updates: Partial<ICurrencyProvider>) => void;
  isLoading: boolean;
}

const Charges = ({ providerData, isLoading, updateValues }: ICharges) => {
  const [open, setOpen] = useState(false);
  const [charge, setCharge] = useState<ICharge>({} as ICharge);

  const submit = () => {
    updateValues({ charge: charge });
  };

  useEffect(() => {
    if (providerData) {
      setCharge(providerData.providers.charge);
    }
  }, [providerData, setCharge]);

  return (
    <div>
      <div onClick={() => setOpen(true)}>
        <ItemCard title="Charges" />
      </div>

      <Modal
        padding={true}
        isVisible={open}
        center
        close={setOpen}
        title="Charges"
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} disabled={isLoading} loading={isLoading}>
              Save Changes
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-4 text-sm">
          <div>
            <h5>Percent</h5>
            <Input
              parentClassName="mt-2"
              defaultValue={charge?.percent}
              onChange={(e) =>
                setCharge({ ...charge, percent: parseInt(e.target.value) })
              }
            />
          </div>
          <div>
            <h5>Fixed</h5>
            <Input
              parentClassName="mt-2"
              defaultValue={charge?.fixed}
              onChange={(e) =>
                setCharge({ ...charge, fixed: parseInt(e.target.value) })
              }
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Charges;
