import copy from 'copy-to-clipboard';
import { format } from 'date-fns';
import _ from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ApprovePayooner from '../../../components/approvePayoneer';
import ButtonDropDOwn from '../../../components/buttonDropDown';
import DeclinePayoneer from '../../../components/declinePayoneer';
import Dropdown from '../../../components/dropdown';
import { ArrowDown, ArrowUp, Copy, Search } from '../../../components/icons';
import Input from '../../../components/input';
import Pagination from '../../../components/pagination';
import { useToast } from '../../../context/toast';
import { IPayoneerResponse } from '../../../interface/payoneer';
import { ITxn, ITxnFilter } from '../../../interface/txn';
import { useDashboardCount } from '../../../services/dashboard';
import { usePayoneer } from '../../../services/payoneer';
import { useTransactions } from '../../../services/transaction';
import { capitalized } from '../../../utils/capitalize';
import { PurposeEnum, StatusEnum } from '../../../utils/constants';
import { isCurrency } from '../../../utils/isCurrency';
import { replaceExtraCharacters } from '../../../utils/replaceExtraCharacters';
import { serviceColors } from '../../../utils/serviceColors';
import useUrlPagination from '../../../utils/urlPagination';
import MessageUser from '../user/details/messageUser';
import TransactionDetails from './details';
import KudaTransactions from './kudaTransaction';
import PayoneerDetails from './payoneerDetails';
import SafeHavenTransaction from './safeHavenTransaction';
import UserBvnDetails from './userBvnDetails';
import UserNinDetails from './userNinDetails';
import Button from '../../../components/button';
import NegativeCardBalances from './negativeCardBalances';

const Transaction = () => {
  const [txnSize, setTxnSize] = useState(10);
  const [payoneersize, setPayoneerSize] = useState(10);
  const [filter, setFilter] = useState<ITxnFilter>();
  const [activeTab, setActiveTab] = useState<string>('general');
  const [open, setOpen] = useState<boolean>(false);
  const [openSafeHaven, setOpenSafeHaven] = useState<boolean>(false);
  const [openNegativeBalance, setOpenNegativeBalance] =
    useState<boolean>(false);
  const { data } = useDashboardCount();
  const { addToast } = useToast();

  const { currentPage, setPage } = useUrlPagination();

  const { data: txn, isLoading } = useTransactions({
    currentPage: activeTab === 'general' ? currentPage : 1,
    size: txnSize,
    ...filter,
  });

  const { data: payoneer, isLoading: payoneerLoading } = usePayoneer({
    currentPage: activeTab === 'payoneer' ? currentPage : 1,
    size: payoneersize,
    status: activeTab === 'payoneer' ? filter?.status : undefined,
    endDate: activeTab === 'payoneer' ? filter?.endDate : undefined,
    startDate: activeTab === 'payoneer' ? filter?.startDate : undefined,
    suspend: activeTab === 'payoneer' ? filter?.suspend : undefined,
    approved: activeTab === 'payoneer' ? filter?.approved : undefined,
    search: activeTab === 'payoneer' ? filter?.search : undefined,
  });

  const tabItems = ['general', 'payoneer'];

  const statusOptions = Object.entries(StatusEnum).map(([label, value]) => ({
    label: capitalized(label),
    value,
  }));

  const purposeOptions = Object.entries(PurposeEnum).map(([label, value]) => ({
    label: capitalized(label).replace(/_/g, ' '),
    value,
  }));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setFilter({ ...filter, search: e.target.value });

  const debouncedResults = useMemo(() => {
    return _.debounce(handleChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    if (filter) setPage(1);
  }, [filter]);

  const airtimeCopy = (item: ITxn) => {
    const purpose = 'Airtime purchase';
    const copiedText = `
    Mode of withdrawal: ${purpose}\nPhone number: ${
      item.view.phone
    }\nAmount: ₦${isCurrency(item.view.amount)}\nNetwork: ${
      item.view.service
    }\nDate: ${item.view.date}`;
    copy(copiedText as string);
    addToast({ msg: 'Copied!', type: 'success' });
  };

  const cheapDataCopy = (item: ITxn) => {
    const purpose = 'Data gifting purchase';
    const copiedText = `
    Mode of withdrawal: ${purpose}\nPhone number: ${
      item.view.phone
    }\nAmount: ₦${isCurrency(item.view.amount)}\nNetwork: ${
      item.view.service
    }\nDate: ${item.view.date}`;
    copy(copiedText as string);
    addToast({ msg: 'Copied!', type: 'success' });
  };

  const regularDataCopy = (item: ITxn) => {
    const purpose = 'Data purchase';
    const copiedText = `
    Mode of withdrawal: ${purpose}\nPhone number: ${
      item.view.phone
    }\nAmount: ₦${isCurrency(item.view.amount)}\nNetwork: ${
      item.view.service
    }\nDate: ${item.view.date}`;
    copy(copiedText as string);
    addToast({ msg: 'Copied!', type: 'success' });
  };

  const powerCopy = (item: ITxn) => {
    const purpose = 'Electricity purchase';
    const copiedText = `
    Mode of withdrawal: ${purpose}\nPhone number: ${
      item.view.phone
    }\nAmount: ₦${isCurrency(item.view.amount)}\nservice: ${
      item.view.service
    }\nDate: ${item.view.date}`;
    copy(copiedText as string);
    addToast({ msg: 'Copied!', type: 'success' });
  };

  const cableCopy = (item: ITxn) => {
    const purpose = 'Cable purchase';
    const copiedText = `
    Mode of withdrawal: ${purpose}\nPhone number: ${
      item.view.phone
    }\nAmount: ₦${isCurrency(item.view.amount)}\nservice: ${
      item.view.service
    }\nDate: ${item.view.date}`;
    copy(copiedText as string);
    addToast({ msg: 'Copied!', type: 'success' });
  };

  const cardsCopy = (item: ITxn) => {
    const purpose = 'Card purchase';
    const copiedText = `
    Mode of withdrawal: ${purpose}\nPhone number: ${
      item.user.phone
    }\nAmount: ₦${isCurrency(item.view.amount)}\nDate: ${item.view.date}`;
    copy(copiedText as string);
    addToast({ msg: 'Copied!', type: 'success' });
  };

  const walletTransferCopy = (item: ITxn) => {
    const purpose = 'Wallet transfer';
    const copiedText = `
    Mode of withdrawal: ${purpose}\nPhone number: ${
      item.user.phone
    }\nAmount: ₦${isCurrency(item.view.amount)}\nTo: ${item.view.to}\nDate: ${
      item.view.date
    }`;
    copy(copiedText as string);
    addToast({ msg: 'Copied!', type: 'success' });
  };

  const bankTransferCopy = (item: ITxn) => {
    const purpose = 'Bank transfer';
    const copiedText = `
    Mode of withdrawal: ${purpose}\nPhone number: ${
      item.user.phone
    }\nAmount: ₦${isCurrency(item.view.amount)}\nDestination account name: ${
      item.view.sourceAccountName
    }\nDestination account number: ${
      item.view.sourceAccountNumber
    }\nDestination bank name: ${item.view.sourceBankName}\nDate: ${
      item.view.date
    }`;
    copy(copiedText as string);
    addToast({ msg: 'Copied!', type: 'success' });
  };

  const giftCardCopy = (item: ITxn) => {
    const purpose = 'Giftcard purchase';
    const copiedText = `
    Mode of withdrawal: ${purpose}\nPhone number: ${
      item.user.phone
    }\nAmount: ₦${isCurrency(item.view.total)}\nProduct name: ${
      item.view.productName
    }\nDate: ${item.view.date}`;
    copy(copiedText as string);
    addToast({ msg: 'Copied!', type: 'success' });
  };

  const vaultCopy = (item: ITxn) => {
    const purpose = 'Vault purchase';
    const copiedText = `
    Mode of withdrawal: ${purpose}\nPhone number: ${
      item.user.phone
    }\nAmount: ₦${isCurrency(item.view.amount)}\nDate: ${item.view.date}`;
    copy(copiedText as string);
    addToast({ msg: 'Copied!', type: 'success' });
  };

  const copyText = (item: ITxn) => {
    switch (item.purpose) {
      case PurposeEnum.AIRTIME:
        airtimeCopy(item);
        break;

      case PurposeEnum.CHEAP_DATA:
        cheapDataCopy(item);
        break;

      case PurposeEnum.DATA:
        regularDataCopy(item);
        break;

      case PurposeEnum.POWER:
        powerCopy(item);
        break;

      case PurposeEnum.CABLE:
        cableCopy(item);
        break;

      case PurposeEnum.CARDS:
        cardsCopy(item);
        break;

      case PurposeEnum.WALLET:
        walletTransferCopy(item);
        break;

      case PurposeEnum.TRANSFER:
        bankTransferCopy(item);
        break;

      case PurposeEnum.GIFT_CARD:
        giftCardCopy(item);
        break;

      case PurposeEnum.VAULT:
        vaultCopy(item);
        break;

      default:
        break;
    }
  };

  const openModals = (item: string) => {
    if (item === 'Kuda') {
      setOpen(true);
    } else if (item === 'Safe Haven') {
      setOpenSafeHaven(true);
    }
  };

  return (
    <>
      <KudaTransactions open={open} setOpen={setOpen} />
      <SafeHavenTransaction open={openSafeHaven} setOpen={setOpenSafeHaven} />
      <NegativeCardBalances
        open={openNegativeBalance}
        setOpen={setOpenNegativeBalance}
      />
      <div className="flex justify-between pb-4">
        <h2 className="font-medium text-grayText">
          Transaction ({txn?.data.pagination.total.toLocaleString()})
        </h2>
        <div className="flex gap-2 items-center">
          <Button
            onClick={() => setOpenNegativeBalance(true)}
            className="text-sm"
          >
            Negative Card Balance
          </Button>
          <ButtonDropDOwn
            label="Provider history"
            option={['Kuda', 'Safe Haven']}
            onClick={(e) => openModals(e!)}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-sm xl:grid-cols-[1.5fr_1fr] gap-4 items-center pb-4">
        <div className="grid md:grid-cols-2  lg:grid-cols-4 gap-4">
          <Input
            type="date"
            className="text-xs"
            leading={<span className="opacity-50">Start</span>}
            onChange={(e) =>
              setFilter({ ...filter, startDate: e.target.value.trim() })
            }
          />
          <Input
            type="date"
            className="text-xs"
            leading={<span className="opacity-50">End</span>}
            onChange={(e) =>
              setFilter({ ...filter, endDate: e.target.value.trim() })
            }
          />
          <Dropdown
            label="Type"
            option={purposeOptions}
            onChange={(e) => setFilter({ ...filter, purpose: e?.value })}
          />
          <Dropdown
            label="Status"
            option={statusOptions}
            onChange={(e) => setFilter({ ...filter, status: e?.value })}
          />
        </div>
        <div className=" relative w-full">
          <Input
            type="search"
            placeholder="search"
            leading={<Search />}
            onChange={debouncedResults}
          />
        </div>
      </div>

      <div className="flex gap-4 mb-4 font-normal">
        <small className="text-lightGray2">Transaction Amount</small>
        <small className="text-grayText">
          ₦{isCurrency(data?.data.txn.txnVolume ?? 0)}
        </small>
      </div>

      <div className="flex gap-4 text-sm mb-4 items-center">
        {tabItems.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              setActiveTab(item);
              setPage(1);
            }}
            className={`${
              activeTab === item
                ? 'text-white bg-activeLinkBlack font-medium px-3 py-[.35rem] mx-1 rounded-md'
                : 'text-grayText'
            } cursor-pointer`}
          >
            {capitalized(item)}
          </div>
        ))}
      </div>

      {activeTab === 'general' && (
        <div className="rounded-md font-normal bg-dimGray border border-grey">
          <div className="overflow-x-auto tableScrollBar ">
            <div className="inline-block min-w-full ">
              <div className=" overflow-x-auto ">
                <table className="table-auto min-w-full">
                  <thead className="bg-lightGray">
                    <tr className="border-b border-grey">
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Date
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Purpose
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Description
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Activity
                      </th>

                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Number
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Amount
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Disbursed Amount
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Settlement
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Initial Balance
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Final Balance
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Transaction ID
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Status
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Customer
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        More
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      txn?.data.response.map((item, index) => (
                        <tr key={item?._id} className={'border-b border-grey'}>
                          <td className="text-sm p-4">
                            {item.createdAt &&
                              format(
                                new Date(item.createdAt),
                                'MMM do, yyyy H:mma'
                              )}
                          </td>
                          <td className="text-sm p-4 flex gap-2">
                            {capitalized(item.purpose)}
                            <span>
                              {item.purpose.includes(PurposeEnum.CHEAP_DATA) ||
                              item.purpose === PurposeEnum.AIRTIME ||
                              item.purpose.includes(PurposeEnum.DATA) ||
                              item.purpose.includes(PurposeEnum.POWER) ||
                              item.purpose.includes(PurposeEnum.TRANSFER) ||
                              item.purpose.includes(PurposeEnum.CABLE) ? (
                                <div
                                  className={`${
                                    item.purpose.includes(PurposeEnum.TRANSFER)
                                      ? serviceColors(
                                          item.view.destinationBankName ||
                                            item.view.sourceBankName
                                        )
                                      : serviceColors(
                                          replaceExtraCharacters(
                                            item.view.service
                                          )
                                        )
                                  } p-1 text-xs rounded-md flex`}
                                >
                                  <div>
                                    {item.purpose.includes(PurposeEnum.TRANSFER)
                                      ? item.view.destinationBankName ||
                                        item.view.sourceBankName
                                      : replaceExtraCharacters(
                                          item.view.service
                                        )}
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </span>
                          </td>
                          <td className="text-sm p-4">
                            {capitalized(item.description)}
                          </td>
                          <td className="text-sm p-4 flex gap-2 items-center">
                            <div
                              className={`p-1 items-center justify-center flex rounded-md ${
                                item.activity === 'DEPOSIT'
                                  ? 'bg-green-500'
                                  : 'bg-red-500'
                              }`}
                            >
                              {item.activity === 'DEPOSIT' ? (
                                <ArrowDown />
                              ) : (
                                <ArrowUp />
                              )}
                            </div>
                            <span>{capitalized(item.activity)}</span>
                          </td>

                          <td className="text-sm p-4">
                            {item.view.meter ??
                              item.view.sourceAccountNumber ??
                              item.view.destinationAccountNumber ??
                              item.view.cardNumber ??
                              item.view.phone}
                          </td>
                          <td className="text-sm p-4">
                            ₦{isCurrency(item.amount ?? 0)}
                            {item.purpose === PurposeEnum.CARDS &&
                              ` / $${isCurrency(item.view.amount ?? 0)}`}
                            {item.purpose === PurposeEnum.GIFT_CARD &&
                              ` / ${
                                item.view.unitPrice.split(' ')[0]
                              } ${isCurrency(
                                Number(item.view.unitPrice.split(' ')[1]) *
                                  item.view.quantity
                              )}`}
                          </td>
                          <td className="text-sm p-4">
                            ₦{isCurrency(item.disbursedAmount ?? 0)}
                            {item.purpose === PurposeEnum.CARDS &&
                              ` / $${isCurrency(item.view.amount ?? 0)}`}
                            {item.purpose === PurposeEnum.GIFT_CARD &&
                              ` / ${
                                item.view.unitPrice.split(' ')[0]
                              } ${isCurrency(
                                Number(item.view.unitPrice.split(' ')[1]) *
                                  item.view.quantity
                              )}`}
                          </td>

                          <td className="text-sm p-4">
                            ₦{isCurrency(item.settlement ?? 0)}
                          </td>
                          <td className="text-sm p-4">
                            ₦
                            {isCurrency(
                              item.meta?.initialBalance ??
                                item.meta?.card?.initialBalance ??
                                0
                            )}
                          </td>
                          <td className="text-sm p-4">
                            ₦
                            {isCurrency(
                              item.meta?.finalBalance ??
                                item.meta?.card?.finalBalance ??
                                0
                            )}
                          </td>
                          <td className="text-sm p-4 flex items-center gap-4">
                            <p>{item.reference}</p>{' '}
                            <span
                              className="cursor-pointer"
                              onClick={() => copyText(item)}
                            >
                              <Copy className="h-4 w-4" />
                            </span>{' '}
                          </td>
                          <td className="text-sm p-4">
                            {item.status === StatusEnum.FAILED && (
                              <span className="text-red-500">
                                {capitalized(StatusEnum.FAILED)}
                              </span>
                            )}
                            {item.status === StatusEnum.REFUNDED && (
                              <span className="text-red-500">
                                {capitalized(StatusEnum.REFUNDED)}
                              </span>
                            )}
                            {item.status === StatusEnum.SUCCESS && (
                              <span className="text-green-500">
                                {capitalized(StatusEnum.SUCCESS)}
                              </span>
                            )}
                            {item.status === StatusEnum.PENDING && (
                              <span className=" text-amber-500">
                                {capitalized(StatusEnum.PENDING)}
                              </span>
                            )}
                            {item.status === StatusEnum.PROCESSING && (
                              <span className=" text-amber-500">
                                {capitalized(StatusEnum.PROCESSING)}
                              </span>
                            )}
                            {item.status === StatusEnum.INITIATED && (
                              <span className=" text-gray-500">
                                {capitalized(StatusEnum.INITIATED)}
                              </span>
                            )}
                          </td>
                          <td className="text-sm flex gap-2 items-center p-4">
                            <Link
                              className="text-blue"
                              to={`/app/user/${item.user?._id}`}
                            >{`${item.user?.firstName} ${item.user?.lastName}`}</Link>
                            {item.user.verification.bvn && (
                              <span>
                                <UserBvnDetails user={item.user} />
                              </span>
                            )}
                            {item.user.verification.nin && (
                              <span>
                                <UserNinDetails user={item.user} />
                              </span>
                            )}
                            <MessageUser user={item.user} button={false} />
                          </td>

                          <td className=" p-4 ">
                            <TransactionDetails
                              item={item}
                              text="More Details"
                            />
                          </td>

                          <td className="text-sm p-4 ">
                            <div
                              className={`${
                                item.business
                                  ? 'bg-amber-500 text-black'
                                  : 'bg-green-500 text-white'
                              } rounded-full w-fit px-2`}
                            >
                              {item.business ? 'Business' : 'Personal'}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {txn?.data.pagination.total! > 0 && (
            <Pagination
              totalItems={txn?.data.pagination.total!}
              itemsPerPage={txnSize}
              setPage={setPage}
              page={currentPage}
              setSize={setTxnSize}
            />
          )}
        </div>
      )}

      {activeTab === 'payoneer' && (
        <div className="rounded-md font-normal bg-dimGray border border-grey">
          <div className="overflow-x-auto tableScrollBar ">
            <div className="inline-block min-w-full ">
              <div className=" overflow-x-auto">
                <table className="table-auto min-w-full">
                  <thead className="bg-lightGray">
                    <tr className="border-b border-grey">
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Date
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Description
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Remarks
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Rate
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Fee
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Disbursed Amount
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Customer
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        More
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                      <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!payoneerLoading &&
                      payoneer?.data.response.map(
                        (item: IPayoneerResponse, index) => (
                          <tr
                            key={item?._id}
                            className={'border-b border-grey'}
                          >
                            <td className="text-sm p-4">
                              {item.createdAt &&
                                format(
                                  new Date(item.createdAt),
                                  'MMM do, yyyy H:mma'
                                )}
                            </td>
                            <td className="text-sm p-4">{`${item?.fromCurrency} - ${item?.toCurrency}`}</td>
                            <td className="text-sm p-4">{item.remark}</td>
                            <td className="text-sm p-4">
                              ₦{isCurrency(item.rate ?? 0)}
                            </td>
                            <td className="text-sm p-4">
                              ₦{isCurrency(item.fee ?? 0)}
                            </td>
                            <td className="text-sm p-4">
                              ₦{isCurrency(item.amountToReceive ?? 0)}/
                              {item.fromCurrency}
                              {item.amount}
                            </td>
                            <td className="text-sm p-4 text-blue">
                              <Link
                                to={`/app/user/${item.user?._id}`}
                              >{`${item.user?.firstName} ${item.user?.lastName}`}</Link>
                            </td>

                            <td className="text-sm p-4 cursor-pointer">
                              <PayoneerDetails item={item} />
                            </td>
                            <td className="text-sm p-4 cursor-pointer">
                              <ApprovePayooner item={item} />
                            </td>
                            <td className="text-sm p-4 cursor-pointer">
                              <DeclinePayoneer item={item} />
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {payoneer?.data.pagination.total! > 0 && (
            <Pagination
              totalItems={payoneer?.data.pagination.total!}
              itemsPerPage={payoneersize}
              setPage={setPage}
              page={currentPage}
              setSize={setPayoneerSize}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Transaction;
