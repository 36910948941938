import { useEffect, useState } from 'react';
import UsersSettlement from '../../../components/barChart/settlement';
import UsersBarChart from '../../../components/barChart/usersChart';
import Dropdown from '../../../components/dropdown';
import CashFlowChart from '../../../components/lineChart/cashFlowChart';
import Revenuechart from '../../../components/lineChart/revenueChart';
import { useBusinessCount } from '../../../services/business';
import {
  useDashboardCount,
  useGetVendorBalance,
} from '../../../services/dashboard';
import { useUserCount } from '../../../services/user';
import { capitalized } from '../../../utils/capitalize';
import { StatusEnum } from '../../../utils/constants';
import { isCurrency } from '../../../utils/isCurrency';
import {
  convertObjectToArray,
  sumArrayValues,
} from '../../../utils/objectToArray';
import {
  AccountNumbersResponse,
  IVendorBalanceArray,
} from '../../../interface/dashboard';
import Button from '../../../components/button';
import { Modal } from '../../../components/modal';
import SuspendedBalance from '../../../components/allModals/suspendedBalance';
import { useGetCards } from '../../../services/cards';
import { useGetSettings } from '../../../services/settings';
import Topup from '../../../components/topUpModal';
import CardCountChart from '../../../components/pieChart/cardCountChart';
import CardSettlementChart from '../../../components/pieChart/cardSettlementtChart';
import CheapDataChart from '../../../components/barChart/cheapDataChart';
import CheapDataGBChart from '../../../components/barChart/cheapDataGBChart';
import { CardVariant } from '../../../interface/cards';
import VendorsAccounts from '../../../components/allModals/vendorsAccounts';
import CardFundingChart from '../../../components/barChart/cardFundingChart';

const Dashboard = () => {
  const [selectedAccount, setSelectedAccount] =
    useState<AccountNumbersResponse>();
  const { data: user } = useUserCount();
  const { data: business } = useBusinessCount();
  const [status, setStatus] = useState();
  const { data: dashboard } = useDashboardCount({ status });
  const { data: vendorBalance } = useGetVendorBalance();
  const { data: rates } = useGetCards({
    conversion: 'USDNGN',
    type: 'mastercard',
    currency: 'usd',
    variant: CardVariant.SUDO,
  });

  const { data: midenRates } = useGetCards({
    conversion: 'USDNGN',
    type: 'mastercard',
    currency: 'usd',
    variant: CardVariant.MIDEN,
  });
  const { data: { data: settings } = {} } = useGetSettings();

  const [modals, setModals] = useState<{
    vendorAccounts: boolean;
    suspendedAccounts: boolean;
    totalVendorbalance: boolean;
    topup: boolean;
  }>({
    vendorAccounts: false,
    suspendedAccounts: false,
    totalVendorbalance: false,
    topup: false,
  });

  const [giftCardRate, setGiftCardRate] = useState(0);
  const [midenMarkup, setMidenMarkup] = useState(0);

  const statusOptions = Object.entries(StatusEnum).map(([label, value]) => ({
    label: capitalized(label),
    value,
  }));

  useEffect(() => {
    const isp = settings?.giftcard.buy.isp.find((item) => item.active);
    if (isp) {
      setGiftCardRate(isp?.currentRate!);
    }
    const miden = settings?.cards.customRates.miden.funding;
    if (miden) {
      setMidenMarkup(miden);
    }
  }, [settings]);

  const reValue = (arr: IVendorBalanceArray[]) => {
    return arr.map((item) => {
      if (item.name === 'sudoUsd' && rates?.data.buy) {
        return {
          ...item,
          value: Number(item.value) * Number(rates?.data.buy),
        };
      }
      if (item.name === 'reloadly' && giftCardRate) {
        return {
          ...item,
          value: Number(item.value) * Number(giftCardRate),
        };
      }

      if (item.name === 'miden' && midenRates?.data.buy) {
        return {
          ...item,
          value:
            Number(item.value) * Number(midenRates?.data.buy - midenMarkup),
        };
      }
      return item;
    });
  };

  const balanceConversion = (item: IVendorBalanceArray) => {
    if (item.name === 'sudoUsd' && rates?.data.buy) {
      return (
        `${isCurrency(item.value)} ≈ ₦${isCurrency(
          Number(item.value) * Number(rates?.data.buy)
        )}` || 0
      );
    }

    if (item.name === 'miden' && midenRates?.data.buy) {
      return (
        `${isCurrency(item.value)} ≈ ₦${isCurrency(
          Number(item.value) * Number(midenRates?.data.buy - midenMarkup)
        )}` || 0
      );
    }

    if (item.name === 'reloadly' && giftCardRate) {
      return (
        `${isCurrency(item.value)} ≈ ₦${isCurrency(
          Number(item.value) * Number(giftCardRate)
        )}` || 0
      );
    }

    return isCurrency(item.value);
  };

  const titleRestructure = (name: string) => {
    switch (name) {
      case 'sudoUsd':
        return 'Sudo USD';

      case 'safeHavenReserve':
        return 'Sudohaven Reserve';

      default:
        return capitalized(name);
    }
  };

  return (
    <>
      <Modal
        isVisible={modals.vendorAccounts}
        center
        padding={false}
        close={(e) => setModals({ ...modals, vendorAccounts: e })}
        width="medium"
        title="Account Numbers"
        header
      >
        <VendorsAccounts
          setSelectedAccount={setSelectedAccount}
          openTopup={() =>
            setModals({ ...modals, topup: true, vendorAccounts: false })
          }
        />
      </Modal>

      <Modal
        isVisible={modals.topup}
        center
        padding={false}
        close={(e) => setModals({ ...modals, topup: e })}
        width="medium"
        title={`Topup ${selectedAccount?.name}`}
        header
      >
        <Topup
          account={selectedAccount!}
          closeModal={() => setModals({ ...modals, topup: false })}
        />
      </Modal>

      <Modal
        isVisible={modals.suspendedAccounts}
        center
        padding={false}
        close={(e) => setModals({ ...modals, suspendedAccounts: e })}
        width="medium"
        title="Suspended Balance"
        header
      >
        <SuspendedBalance suspendedBalance={dashboard?.data.suspendedWallet!} />
      </Modal>

      <Modal
        isVisible={modals.totalVendorbalance}
        center
        padding={false}
        close={(e) => setModals({ ...modals, totalVendorbalance: e })}
        width="medium"
        title="Vendor Total"
        header
      >
        <div className="flex justify-between p-4 !text-xs">
          <p>Balance</p>
          <p className="font-medium">
            ₦
            {isCurrency(
              sumArrayValues(reValue(convertObjectToArray(vendorBalance?.data)))
            )}
          </p>
        </div>
      </Modal>

      <div className="flex items-center justify-between pb-4">
        <h2 className="font-medium  text-grayText">Dashboard</h2>

        <Button
          bgColor="bg-activeLinkBlack"
          onClick={() => setModals({ ...modals, suspendedAccounts: true })}
          className="!text-xs"
        >
          Suspended Balance
        </Button>
      </div>
      <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 border border-grey rounded-md bg-dimGray font-normal text-grayText">
        <div className="p-4  border-b lg:border-b-0 border-grey">
          <small className="opacity-70">Total wallet balance</small>
          <h3 className="font-medium">
            ₦
            {dashboard?.data
              ? isCurrency(
                  dashboard!.data.userWallet + dashboard!.data.businessWallet
                )
              : 0}
          </h3>
        </div>
        <div className="p-4  border-b lg:border-b-0 border-grey">
          <small className="flex gap-2 items-center">
            <div className="opacity-70">Transaction volume</div>
            <span>
              <Dropdown
                label={''}
                option={statusOptions}
                onChange={(e) => setStatus(e?.value)}
                dropdownClassName="!w-[200px]"
                parentClassName="w-auto py-1 text-xs px-2 "
              />
            </span>
          </small>
          <h3 className="font-medium">
            ₦{isCurrency(dashboard?.data.txn.txnVolume ?? 0)}
          </h3>
        </div>
        <div className="p-4 border-b lg:border-b-0 border-grey ">
          <small className="opacity-70">No of transaction</small>
          <h3 className="font-medium">
            {dashboard?.data.txn.count.toLocaleString()}
          </h3>
        </div>

        <div className="p-4 border-b md:border-b-0 border-grey">
          <small className="opacity-70">Profit</small>
          <h3 className="font-medium">
            ₦{isCurrency(dashboard?.data.txn.txnSettlement ?? 0)}
          </h3>
        </div>
        <div className="p-4">
          <small className="opacity-70">Cashback</small>
          <h3 className="font-medium">
            ₦{isCurrency(dashboard?.data.txn.txnCashback ?? 0)}
          </h3>
        </div>
      </div>

      <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 border border-grey my-6 rounded-md bg-dimGray font-normal text-grayText">
        <div className="p-4 border-b lg:border-b-0 border-grey">
          <small className="opacity-70">User wallet balance</small>
          <h3 className="font-medium">
            ₦{isCurrency(dashboard?.data.userWallet ?? 0)}
          </h3>
        </div>
        <div className="p-4 border-b lg:border-b-0 border-grey">
          <small className="opacity-70">User</small>
          <h3 className="font-medium">{user?.data.allUser.toLocaleString()}</h3>
        </div>
        <div className="p-4 border-b lg:border-b-0 border-grey ">
          <small className="opacity-70">Verified user</small>
          <h3 className="font-medium">
            {user?.data.verifiedUser.toLocaleString()}
          </h3>
        </div>
        <div className="p-4 border-b md:border-b-0 border-grey">
          <small className="opacity-70">Unverified user</small>
          <h3 className="font-medium">
            {user?.data.unverifiedUser.toLocaleString()}
          </h3>
        </div>
        <div className="p-4">
          <small className="opacity-70">Suspend user</small>
          <h3 className="font-medium">
            {user?.data.suspendedUser.toLocaleString()}
          </h3>
        </div>
      </div>

      <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 border border-grey my-6  rounded-md bg-dimGray font-normal text-grayText">
        <div className="p-4 border-b lg:border-b-0 border-grey">
          <small className="opacity-70">Business wallet balance</small>
          <h3 className="font-medium">
            ₦{isCurrency(dashboard?.data.businessWallet ?? 0)}
          </h3>
        </div>
        <div className="p-4 border-b lg:border-b-0 border-grey">
          <small className="opacity-70">Business</small>
          <h3 className="font-medium">{business?.data.allBusiness}</h3>
        </div>
        <div className="p-4 border-b lg:border-b-0 border-grey ">
          <small className="opacity-70">Verified business</small>
          <h3 className="font-medium">{business?.data.verifiedBusiness}</h3>
        </div>
        <div className="p-4 border-b md:border-b-0 border-grey">
          <small className="opacity-70">Unverified business</small>
          <h3 className="font-medium">{business?.data.unverifiedBusiness}</h3>
        </div>
        <div className="p-4">
          <small className="opacity-70">Suspend business</small>
          <h3 className="font-medium">{business?.data.suspendedBusiness}</h3>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-8">
        <UsersBarChart />
        <UsersSettlement />
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-8">
        <Revenuechart />
        <CashFlowChart />
      </div>
      <div className="">
        <CardFundingChart />
      </div>
      <div className="">
        <CheapDataChart />
      </div>
      <div className="">
        <CheapDataGBChart />
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-8 overflow-y-hidden">
        <CardCountChart />
        <CardSettlementChart />
      </div>

      <div className="mt-14">
        <div className="flex items-center gap-4 pb-4">
          <h2 className="font-medium text-grayText">Vendor Balance</h2>
          <Button
            bgColor="bg-activeLinkBlack"
            onClick={() => setModals({ ...modals, totalVendorbalance: true })}
            className="!text-xs mr-1"
          >
            Total
          </Button>
          <Button
            bgColor="bg-activeLinkBlack"
            onClick={() => setModals({ ...modals, vendorAccounts: true })}
            className="!text-xs"
          >
            Accounts
          </Button>
        </div>
        <div className="grid gap-8 lg:grid-cols-4 md:grid-cols-3 grid-cols-1 font-normal text-grayText">
          {vendorBalance && dashboard && rates && (
            <div className="p-4 rounded-md bg-black">
              <small className="opacity-70">Estimation Calculator</small>
              <h3 className="font-medium">
                ₦
                {isCurrency(
                  sumArrayValues(
                    reValue(convertObjectToArray(vendorBalance?.data))
                  ) -
                    (dashboard!.data.userWallet +
                      dashboard!.data.businessWallet)
                )}
              </h3>
            </div>
          )}
          {convertObjectToArray(vendorBalance?.data).map(
            (item: IVendorBalanceArray, index) => (
              <div
                className="p-4 border rounded-md bg-dimGray border-grey"
                key={index}
              >
                <small className="opacity-70">
                  {titleRestructure(item.name)}
                </small>
                <h3 className="font-medium">
                  {item.name === 'sudoUsd' ||
                  item.name === 'reloadly' ||
                  item.name === 'miden'
                    ? '$'
                    : '₦'}
                  {balanceConversion(item)}
                </h3>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
